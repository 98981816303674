
.amount-modal {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;

    &__input {
      border: none;
      border-radius: 13px;
      color: #fff;
      background-color: rgba(255, 255, 255, 0.1);
      height: 40px;
      font-family: inherit;
      font-size: 16px;
      font-weight: 700;
      padding: 0 12px;

      &:focus {
        outline: none;
      }
    }

    &__btngp {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      gap: 8px;


      button {
        flex: 1;
        color: #fff;
        border: 1px solid rgba(255, 255, 255, 0.05);
        background-image: linear-gradient(
          119.45deg,
          #8239ff00 8.65%,
          #e18dff00 41.44%,
          #ff94d400 68.05%
        );
        border-radius: 8px;
        height: 24px;
        outline: none;
        position: relative;

        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          border-radius: 8px;
          background-image: linear-gradient(
            119.45deg,
            #8239ff 8.65%,
            #e18dff 41.44%,
            #ff94d4 68.05%
          );
          opacity: 0;
          transition: all 0.3s ease;
        }
        &:hover::before {
          opacity: 1;
        }

        span {
          display: block;
          position: relative;
          z-index: 2;
        }
      }
    }

    .amount-container {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      gap: 12px;

    }
    
    .modal-btn {
      width: 100%;
      margin-top: 24px;
    }
  }