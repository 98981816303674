
.initial-modal {
  .initial-modal-title {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    font-size: 16px;
    line-height: 24px;
    padding: 14px 16px;
    margin-top: 12px;
    color: rgba(255, 255, 255, 0.6);
  }
  .initial-modal-item {
    padding-bottom: 16px;
    margin-top: 16px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);

    a {
      text-decoration: underline;
    }
  }


  .checkbox-container {
    text-align: center;
    padding: 20px 0 20px 32px;
    color: rgba(255, 255, 255, 0.4);
    position: relative;
    display: block;
    width: fit-content;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-size: 16px;
    line-height: 24px;

    @media (max-width: 850px) {
      text-align: left;
      padding: 20px 0 0 32px;
    }

  }
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    &:checked {
      & ~ .checkmark {
        &:after {
          display: block;
        }
      }
    }
  }

  .checkmark {
    position: absolute;
    top: calc(50% - 12px);
    left: 0px;
    height: 24px;
    width: 24px;
    background-color: transparent;
    border: 2px solid rgba(255, 255, 255, 0.4);
    border-radius: 4px;

    @media (max-width: 850px) {
      top: calc(50% - 2px);
    }

    &:after {
      content: "";
      position: absolute;
      display: none;
      left: 6px;
      top: 2px;
      width: 6px;
      height: 10px;
      border: solid white;
      border-width: 0 2px 2px 0;
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
}
