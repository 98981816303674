.main-container {
  padding-top: 88px;
  position: relative;

  @media (max-width: 767px) {
    padding-top: 64px;
  }

  .bg-shape1 {
    position: absolute;
    top: -10%;
    right: -20%;
    width: 425px;
    height: 320px;
    background: #2710b4;
    filter: blur(182px);
    transform: rotate(-13.54deg);
    z-index: -1;

    @media (max-width: 767px) {
      display: none;
    }
  }
  .bg-shape2 {
    position: absolute;
    top: -10%;
    right: 100px;
    width: 302px;
    height: 340px;
    background: #b4105f;
    opacity: 0.4;
    filter: blur(125px);
    transform: rotate(-75deg);
    z-index: -1;

    @media (max-width: 767px) {
      display: none;
    }
  }
  .bg-shape3 {
    position: absolute;
    left: -10%;
    top: -20%;
    width: 425px;
    height: 420px;
    background: #2710b4;
    filter: blur(182px);
    transform: rotate(-13.54deg);
    z-index: -1;

    @media (max-width: 767px) {
      display: none;
    }
  }
  .bg-shape4 {
    position: absolute;
    bottom: -10%;
    right: -20%;
    width: 425px;
    height: 220px;
    background: #2710b4;
    filter: blur(182px);
    transform: rotate(-13.54deg);
    z-index: -1;

    @media (max-width: 767px) {
      display: none;
    }
  }

  .main-title {
    font-size: 40px;
    line-height: 56px;
    padding-top: 48px;
    text-align: center;
    font-weight: 500;

    @media (max-width: 767px) {
      padding-top: 40px;
    }
  }
  .cards-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 64px 32px;
    margin-top: 48px;
    margin-bottom: 56px;
    @media (max-width: 1200px) {
      grid-template-columns: repeat(2, 1fr);
      gap: 32px 32px;
    }
    @media (max-width: 768px) {
      grid-template-columns: repeat(1, 1fr);
      gap: 8px 8px;
      margin-top: 46px;
      margin-bottom: 72px;
    }
  }
  .stake-section {
    position: relative;

    @media (max-width: 767px) {
      margin: 0 -24px;
      padding: 24px;
      background: rgba(255, 255, 255, 0.05);
    }

    .stake-note {
      width: 60%;
      margin: 1px auto 16px;

      @media (max-width: 850px) {
        width: 100%;
      }

      @media (max-width: 767px) {
        margin: 16px 0 0;
      }
    }
    &:before {
      position: absolute;
      content: '';
      top: 0;
      width: 100%;
      height: 1px;
      left: 0;
      background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 0.3),
        rgba(255, 255, 255, 0)
      );


    @media (max-width: 767px) {
      display: none;
    }
    }
    .checkbox-container {
      text-align: center;
      padding: 26px 0 38px 32px;
      color: rgba(255, 255, 255, 0.4);
      position: relative;
      display: block;
      width: fit-content;
      margin: auto;
      cursor: pointer;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      font-size: 16px;
      line-height: 24px;

      @media (max-width: 850px) {
        text-align: left;
      }

      @media (max-width: 850px) {
        padding: 0 0 0 32px;
      }
    }
    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
      &:checked {
        & ~ .checkmark {
          &:after {
            display: block;
          }
        }
      }
    }
  }
  .checkmark {
    position: absolute;
    top: calc(50% - 18px);
    left: 0px;
    height: 24px;
    width: 24px;
    background-color: transparent;
    border: 2px solid rgba(255, 255, 255, 0.4);
    border-radius: 4px;

    @media (max-width: 850px) {
      top: calc(50% - 12px);
    }

    &:after {
      content: "";
      position: absolute;
      display: none;
      left: 6px;
      top: 2px;
      width: 6px;
      height: 10px;
      border: solid white;
      border-width: 0 2px 2px 0;
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
  .btn {
    width: 30%;
    margin: auto;
    margin-bottom: 124px;

    @media (max-width: 850px) {
      width: 100%;
    }

    @media (max-width: 767px) {
      margin-bottom: 0;
      margin-top: 24px;
    }
  }
}
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(4px);
  z-index: 40;
  &.not-active {
    opacity: 0;
    visibility: hidden;
  }
}

.stake-subtitle {
  color: rgba(255, 255, 255, 0.4);
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  margin: 16px 0;
}
.stake-note {
  font-size: 12px;
  line-height: 20px;
  color: rgba(255, 255, 255, 0.6);
  width: 32%;
  margin: -40px auto 16px;
  @media (max-width: 992px) {
    width: 60%;
  }
  @media (max-width: 850px) {
    width: 100%;
  }
}
.stake-alarm-container {
  padding: 32px 0;
  padding-bottom: 0;


  @media (max-width: 767px) {
    padding: 0;
    margin-bottom: 16px;
  }

  .stake-alarm {
    background: rgba(255, 255, 255, 0.05);
    border-radius: 12px;
    text-align: center;
    padding: 24px;
    width: 60%;
    margin: auto;
    font-size: 14px;
    line-height: 24px;
    @media (max-width: 850px) {
      width: 100%;
    }
  }
}

.highlighted {
  border-bottom: 1px solid rgba(255, 255, 255, 0.4);
}

.spacer {
  padding-bottom: 36px;

  @media (max-width: 767px) {
    display: none;
  }

}